import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"

const imageShrimpCraw = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/shrimpcrawfishskewer_large.jpg';
const RecipePage = () => (
    <Layout metaTitle="Bacon, Shrimp and Crawfish Skewers Recipe"
        metaDescription="It’s surf & turf on our home turf: thick-cut bacon. The hearty flavor blends right in with Creole-seasoned shrimp & crawfish. View the full recipe & get cookin'!"
        metaKeywords="bacon wrapped shrimp"
    >
        <meta property="og:image" content={imageShrimpCraw} />
        <link rel="image_src" href={imageShrimpCraw} />
        <div id="recipe-details-wrapper" >
            <div class="image">
                <img src={imageShrimpCraw} class='img-responsive' alt='Shrimp Crawfish Skewer' />
            </div>

            <div class="inner-wrapper">
                <div id="recipe-details">
                    <div class="container">
                        <div class="content-wrapper">
                            <h1>Bacon, Shrimp and Crawfish Skewers</h1>

                            <div class="recipe-content">
                                <p >It&rsquo;s surf and turf on our home turf: thick-cut bacon. The hearty flavor blends right in with Creole-seasoned shrimp and crawfish. Corn and potato provide a nice base for a buttery sauce with plenty of Creole bite.&nbsp;</p>
                                <p><br />
                                </p>
                            </div>
                        </div>

                        <div class="timing row">
                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/prepIcon.png" alt="Prep Time" />
                                </div>
                                <div class="details">
                                    <h4>Prep Time:</h4>
                                    <p>
                                        15&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/cookIcon.png" alt="Cook Time" />
                                </div>
                                <div class="details">
                                    <h4>Cook Time:</h4>
                                    <p>
                                        30&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/serveIcon.png" alt="Serving Size" />
                                </div>
                                <div class="details">
                                    <h4>Serving Size:</h4>
                                    <p>4 </p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div class="ingredients-wrapper recipe-section">
                    <div class="container">


                        <h2>Ingredients</h2>
                        <div class="row ingredients">
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                12 <span class="uom"></span> slices Wright® Brand, Thick-Cut Bacon
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                12 <span class="uom"></span> large shrimp, shell on
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                12 <span class="uom"></span> crawfish
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                <span class="uom"></span> Creole seasoning to taste
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                8 <span class="uom"></span> corn on the cob, halved
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                8 <span class="uom"></span> baby red potatoes, halved
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                12 <span class="uom"></span> wood skewers, 12”, soaked
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1/3 <span class="uom">Cup</span> melted butter
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 <span class="uom">Tablespoon</span> crab boil concentrate
                </div>
                        </div>


                    </div>
                </div>

                <div class="instructions-wrapper recipe-section">
                    <div class="container">


                        <h2>Directions</h2>
                        <div class="instructions">
                            <p>
                                1. Preheat grill at medium heat.

                </p>
                            <p>
                                2. Carefully skewer shrimp and crawfish, and season with as much Creole seasoning as you like.

                </p>
                            <p>
                                3. Fold bacon accordion-style into cubes about 1” tall and stack onto a skewer.

                </p>
                            <p>
                                4. Place bacon on the coolest area of the grill, turning until edges start to crisp. Reserve in a large mixing bowl and keep warm.

                </p>
                            <p>
                                5. Season corn and potatoes with Creole seasoning. Place on the grill and cook until potatoes are fork tender and corn is cooked thoroughly. Reserve with cooked bacon.

                </p>
                            <p>
                                6. Cook crawfish and shrimp until they reach an internal temperature of 145º F. Reserve in the large mixing bowl.

                </p>
                            <p>
                                7. Combine melted butter and crab boil concentrate in mixing bowl holding bacon, crawfish, shrimp and vegetables. Toss to evenly distribute. Serve.

                </p>
                        </div>


                        <div></div>
                    </div>
                </div>
            </div>
        </div>


        <article class="locator-callout">
            <div class="quick-form-container">


                <div class="product-locator-search">
                    <h2>Find Our Products</h2>
                    <form data-url="/locator/" action="/locator/" method="get">
                        <input Value="ANY_ANY" id="PROD" name="PROD" type="hidden" value="ANY_ANY" />
                        <div class="input-wrapper">
                            <input data-val="true" data-val-regex="Please enter a 5-digit ZIP code" data-val-regex-pattern="^\d{5}" data-val-required="Please enter a 5-digit ZIP code" id="ZIP" maxLength="5" name="ZIP" placeholder="Enter Zip" type="tel" defaultValue="" />
                            <input type="submit" value="Locate Bacon"></input>
                            <span class="field-validation-valid" data-valmsg-for="ZIP" data-valmsg-replace="true"></span>
                        </div>
                    </form>
                </div>

            </div>
        </article>
    </Layout>
)

export default RecipePage
